import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-custom-input-field',
  templateUrl: './custom-input-field.component.html',
  styleUrls: ['./custom-input-field.component.scss']
})
export class CustomInputFieldComponent implements OnInit, OnDestroy {
  @Input() error = false;
  @ViewChild('errorsBox', {static: true})
  errorsBox: ElementRef;
  errorObserver: MutationObserver;

  constructor() { }

  ngOnInit(
  ): void {
    // Hack to make the errors box smoothly change height
    this.errorObserver = new MutationObserver((mutations) => {
      mutations.forEach(mutation => {
        const el = mutation.target as HTMLElement;
        let height = 0;
        for (let i = 0; i < el.children.length; ++i) {
          height += el.children.item(i).clientHeight;
        }
        el.style.height = `${height}px`;
      });
    });

    this.errorObserver.observe(this.errorsBox.nativeElement, {
      attributes: true,
      childList: true,
    });
  }

  ngOnDestroy(): void {
    this.errorObserver.disconnect();
  }
}
