<div class="wrapper" *ngIf="caseData && !loading">
  <app-header></app-header>
  <div class="content flex column vcenter">
    <div *ngIf="windowWidth>950">
      <div class="flex grow1">
        <div class="grow1">
          <app-details [caseData]="caseData"></app-details>
        </div>
        <div>
          <app-countdown *ngIf="!isCasePaused(caseData)" [caseData]="caseData">
          </app-countdown>
        </div>
      </div>
      <app-claims-list [caseData]="caseData" *ngIf="claimsListOpen"></app-claims-list>
      <mat-divider></mat-divider>
      <app-deposit id="deposit01" [caseData]="caseData" (toggleClaimsList)="claimsListOpen = !claimsListOpen"></app-deposit>
    </div>
    <div *ngIf="windowWidth<=950">
      <app-details [caseData]="caseData"></app-details>
      <mat-divider></mat-divider>
      <app-deposit id="deposit02" [caseData]="caseData" (toggleClaimsList)="claimsListOpen = !claimsListOpen">
      </app-deposit>
      <mat-divider></mat-divider>
      <app-claims-list [caseData]="caseData" *ngIf="claimsListOpen"></app-claims-list>
      <app-countdown *ngIf="!isCasePaused(caseData)" [caseData]="caseData">
      </app-countdown>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<div class="loading-wrapper flex column vcenter hcenter" *ngIf="loading">
  <img src="assets/payee-logo-white.svg" width="149px">
  <img src="assets/Bean Eater-1s-207px.svg" height="95px" class="loading-animation">
</div>
