import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetCardPaymentStatus, PaymentService, PaymentStatus } from '../../services/payment/payment.service';

@Component({
  selector: 'app-payment-redirect-step',
  templateUrl: './payment-redirect-step.component.html',
  styleUrls: ['./payment-redirect-step.component.scss']
})
export class PaymentRedirectStepComponent implements OnInit {
  cardPaymentStatus?: GetCardPaymentStatus;

  private retryCount = 10;
  private retryInterval: ReturnType<typeof setInterval>;

  private readonly caseId: string;
  private readonly paymentId: string;

  private paymentInProgressStatuses: PaymentStatus[] = [
    'Started',
    'InProgress',
    'Waiting',
    'Reserved',
    'Authorized',
  ];

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.caseId = this.route.snapshot.params.uuid;
    this.paymentId = this.route.snapshot.queryParams.paymentId;
  }

  async ngOnInit() {
    if (!this.paymentId) {
      await this.router.navigateByUrl(`${this.caseId}`);
      return;
    }

    this.retryInterval = setInterval(async () => {
      await this.getCardPaymentStatus();

      if (this.cardPaymentStatus) {
        if (this.cardPaymentStatus.status === 'Succeeded') {
          await this.router.navigate(['payment-success']);
          clearInterval(this.retryInterval);
          return;
        } else if (!this.paymentInProgressStatuses.includes(this.cardPaymentStatus.status)) {
          await this.router.navigateByUrl(`${this.caseId}`);
          clearInterval(this.retryInterval);
          return;
        }
      }

      --this.retryCount;

      if (this.retryCount <= 0) {
        clearInterval(this.retryInterval);
        await this.router.navigateByUrl(`${this.caseId}`);
      }
    }, 1000);
  }

  async getCardPaymentStatus() {
    try {
      this.cardPaymentStatus = await this.paymentService.getCardPaymentStatus(this.paymentId);
    } catch (error) {
      console.error('Error while getting payment status', error);
    }
  }
}
