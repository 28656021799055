<!-- <app-language-selector></app-language-selector> -->

<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img src="assets/checkmark.svg" height="71.43px" />
  </div>
  <div class="title flex vcenter">Ez a tartozás már ki lett fizetve!</div>
  <div class="description grow1 flex vcenter">
    Ezzel további teendő nincsen.
  </div>
  <a href="https://payee.tech">
    <button mat-button class="confirm-button">Rendben</button>
  </a>
</div>
