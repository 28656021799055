import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseData } from 'src/app/models/case-data.model';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-plan-start-dialog',
  templateUrl: './payment-plan-start-dialog.component.html',
  styleUrls: ['./payment-plan-start-dialog.component.scss']
})
export class PaymentPlanStartDialogComponent implements OnInit {
  caseData: CaseData;

  agreementForm: FormGroup;

  paymentPlans = new Map();
  selectedPayment = 0;
  keyId: string;
  loading = false;
  ppStarted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PaymentPlanStartDialogComponent>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.agreementForm = fb.group({
      pp: [false, [Validators.requiredTrue]],
      aszf: [false, [Validators.requiredTrue]],
    });

    this.caseData = data.caseData;
    this.keyId = data.keyId;
  }

  get selectedPaymentPlans() { return this.paymentPlans.get(this.selectedPayment); }

  get aszf() { return this.agreementForm.get('aszf'); }
  get pp() { return this.agreementForm.get('pp'); }

  async ngOnInit() {
    await this.selectPaymentPlan(this.caseData.payment_plan_options[0]);
  }

  async selectPaymentPlan(count: number) {
    throw new Error('Unimplemented');
  }

  async startPaymentPlan() {
    this.loading = true;
    throw new Error('Unimplemented');

    this.dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    });
    this.loading = false;
    this.ppStarted = true;
  }
}
