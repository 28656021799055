import { Component, Input, OnInit } from '@angular/core';
import { CaseData } from 'src/app/models/case-data.model';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss']
})
export class BankTransferComponent implements OnInit {
  @Input() caseData: CaseData;

  get bankAccount() { return this.caseData?.client_bankaccount; }

  constructor() { }

  ngOnInit(): void {
  }

  copyToClipboard(e: HTMLInputElement) {
    e.select();
    e.setSelectionRange(0, 99999);

    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }
}
