import { Component, Input } from '@angular/core';
import { CaseData } from 'src/app/models/case-data.model';

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['./claims-list.component.scss']
})
export class ClaimsListComponent {
  @Input() caseData: CaseData;
}
