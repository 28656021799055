import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  siteLanguage = 'Hungarian';
  siteLocale: string;
  languageList = [
    { code: 'hu', label: 'Hungarian' },
    { code: 'ro', label: 'Romanian' },
  ];

  ngOnInit(): void {
    this.siteLocale = window.location.pathname.split('/')[1];
    this.siteLanguage = this.languageList.find(f => f.code === this.siteLocale)?.label;
  }

  changeLanguage(lang: string) {
    if (this.siteLocale === lang) {
      return;
    }

    const path = window.location.pathname.split('/');
    path[1] = lang;
    window.location.assign(path.join('/'));
  }
}
