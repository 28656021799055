import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CaseData } from 'src/app/models/case-data.model';

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss'],
})
export class PaymentPlanComponent {
  @Input() caseData: CaseData;
  @Output() paymentSelected = new EventEmitter<number>();

  get firstPPIndex() {
    const index = this.caseData.payment_plans.map(pp => pp.status).indexOf('pending');

    if (index !== -1 && this.selectedPayment === -1) {
      this.selectedPayment = index;
      this.paymentSelected.emit(index);
    }

    return index;
  }
  selectedPayment = -1;

  selectPayment(index: number) {
    this.selectedPayment = index;

    this.paymentSelected.emit(index);
  }
}
