import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CaseData } from 'src/app/models/case-data.model';

import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PaymentPlanStartDialogComponent } from 'src/app/dialogs/payment-plan-start-dialog/payment-plan-start-dialog.component';
import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent {
  @Input() caseData: CaseData;
  selectedPayment = -1;
  paymentForm: FormGroup;
  claimsListOpen = false;
  @Output() toggleClaimsList = new EventEmitter();
  paymentLoading = false;
  docID: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private paymentService: PaymentService,
    ) {
    this.paymentForm = this.fb.group({
      aszf: [false, [Validators.requiredTrue]],
      pp: [false, [Validators.requiredTrue]]
    });
    this.docID = this.route.snapshot.params.docID;
  }

  get firstPPIndex() {
    return this.caseData.payment_plans.map(pp => pp.status).indexOf('pending');
  }

  get paymentAmount() {
    if (!this.caseData.is_payment_plan) {
      return this.caseData.remaining_amount;
    }

    if (this.selectedPayment < this.firstPPIndex || this.selectedPayment >= this.caseData.payment_plans.length) {
      return 0;
    }

    let sum = 0;
    this.caseData.payment_plans.slice(this.firstPPIndex, this.selectedPayment + 1).forEach(pp => sum += pp.paymentAmountCurrent);
    return sum;
  }

  get aszf() { return this.paymentForm.get('aszf'); }
  get pp() { return this.paymentForm.get('pp'); }

  ppSelected(index: number) {
    this.selectedPayment = index;
  }

  async startPayment() {
    this.paymentLoading = true;

    try {
      await this.paymentService.startPayment({
        caseUuid: this.docID,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.paymentLoading = false;
    }
  }

  startPaymentPlan() {
    this.dialog.open(PaymentPlanStartDialogComponent, {
      autoFocus: false,
      position: {
        top: '50px',
      },
      data: {
        caseData: this.caseData,
        keyId: this.docID,
      },
    });
  }
}
