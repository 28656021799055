import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import axios from 'axios';

export type PaymentStatus =
  'Prepared'
  | 'Started'
  | 'InProgress'
  | 'Waiting'
  | 'Reserved'
  | 'Authorized'
  | 'Canceled'
  | 'Succeeded'
  | 'Failed'
  | 'PartiallySucceeded'
  | 'Expired';

export type GetCardPaymentStatus = {
  total: number;
  currency_iso: string;
  status: PaymentStatus;
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  async startPayment({ caseUuid }: { caseUuid: string }) {
    const url = environment.baseUrl + `/api/case/${caseUuid}/pay-with-card`;
    const response = await axios.get<string>(url);

    window.location.href = response.data;
  }

  async getCardPaymentStatus(paymentId: string): Promise<GetCardPaymentStatus> {
    const url = environment.baseUrl + `/api/get-card-payment-status/${paymentId}`;
    const response = await axios.get<GetCardPaymentStatus>(url);

    return response.data;
  }

  async getBarionLog(id: string): Promise<unknown> {
    throw new Error('Unimplemented');
  }

  async getPaymentSiteUrl(idOfClient: string, idOfCase: string): Promise<string> {
    throw new Error('Unimplemented');
  }
}
