<!-- <app-language-selector></app-language-selector> -->

<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img src="assets/red_cross.svg" height="42.86px" />
  </div>
  <div class="title flex vcenter">
    Ez az ügy le lett állítva vagy törlésre került.
  </div>
  <div class="description grow1 flex vcenter">
    A hiteleződ leállította folyamatot vagy törlésre került az ügyed.
  </div>
  <a href="https://payee.tech">
    <button mat-button class="confirm-button">Rendben</button>
  </a>
</div>
