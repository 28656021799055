import { CaseData } from '../../../models/case-data.model';
import { Component, Input } from '@angular/core';

import * as smoothscroll from 'smoothscroll-polyfill';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {
  @Input() caseData: CaseData;
  showFiles = false;
  showClaims = false;

  showFullEvidenceDetails = false;
  croppedEvidenceDetailsLength = 80;

  constructor() {
    smoothscroll.polyfill();
  }

  get evidenceDetailsCropped() {
    if (!this.caseData || !this.caseData.description) {
      return null;
    }

    return this.caseData.description.slice(0, this.croppedEvidenceDetailsLength) + '...';
  }

  scrollToDeposit(): void {
    const deposit = document.getElementById('deposit');
    deposit.scrollIntoView({
      behavior: 'smooth'
    });
  }

  getCroppedFileName(fileName: string) {
    const lastDot = fileName.lastIndexOf('.');
    if (lastDot <= 20) {
      return fileName;
    }

    return fileName.substr(0, 8) + ' ... ' + fileName.substr(lastDot - 8);
  }
}
