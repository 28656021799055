<div class="wrapper">
  <div class="logo-box flex vcenter">
    <!-- <app-language-selector></app-language-selector> -->
    <a href="https://www.payee.tech" target="_blank">
      <img src="assets/payee-logo-white.svg" width="72px" />
    </a>
  </div>
  <div class="title">FIZETÉSI FELSZÓLÍTÁS</div>
  <div class="help-box">
    <a href="https://payee.tech/adosoknak" target="_blank">
      <div>Miért kaptam ezt?</div>
      <img src="assets/link.svg" alt="" />
    </a>
  </div>
</div>
