import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent {
  @Input() checked: boolean;
  @Output() onclick = new EventEmitter<any>();

  constructor() { }

  onClick() {
    this.onclick.emit();
  }
}
