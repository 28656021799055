<div class="wrapper flex column vcenter hcenter">
  <div *ngIf="!caseData.is_payment_plan">Fizetési felszólításból hátravan:</div>
  <div *ngIf="caseData.is_payment_plan">Aktuális részlet határideje</div>
  <div class="flex counter hcenter">
    <div>
      <div class="digit">{{ day }}</div>
      <div class="unit">nap</div>
    </div>
    <div class="separator">&nbsp;:&nbsp;</div>
    <div>
      <div class="digit">{{ hour }}</div>
      <div class="unit">óra</div>
    </div>
    <div class="separator">&nbsp;:&nbsp;</div>
    <div>
      <div class="digit">{{ minute }}</div>
      <div class="unit">perc</div>
    </div>
    <div class="separator">&nbsp;:&nbsp;</div>
    <div>
      <div class="digit">{{ second }}</div>
      <div class="unit">sec</div>
    </div>
  </div>
  <ng-container *ngIf="!caseData.is_payment_plan">
    <div class="hard-collection-at">
      LEJÁR EKKOR:
      {{ caseData.hard_collection_at | date : "yyyy.MM.dd. HH:mm" }}
    </div>
    <div class="warning-message">
      Amennyiben nem rendezi a tartozást, a hitelező jogi eszközöket vehet
      igénybe, aminek a költsége megnövelheti a tartozását.
    </div>
  </ng-container>
  <ng-container *ngIf="caseData.is_payment_plan">
    <div class="hard-collection-at">
      LEJÁR EKKOR: {{ firstPaymentPlanDueDate | date : "yyyy.MM.dd. HH:mm" }}
    </div>
    <div class="warning-message">
      Ha nem fizeted be, az ügy
      <strong>automatikusan folytatódik a jogi szakasszal.</strong>
    </div>
  </ng-container>
</div>
<div
  class="hard-col-amount flex column hcenter"
  *ngIf="!caseData.is_payment_plan"
>
  <div class="title">
    Jogi eszköz igénybevétele esetén a törvény szerint a költségeket az adósnak
    kell megfizetnie, amivel együtt a tartozás teljes összege:
  </div>
  <div class="amount">
    {{ caseData.amount_after_legal_fees | number : "1.0" : "hu" }}
    {{ caseData.currency }}
  </div>
</div>
