<div class="wrapper">
  <ng-container *ngIf="!ppStarted">
    <div class="img-box flex vcenter hcenter">
      <img src="assets/percentage.svg" width="57.14px" height="57.14px" />
    </div>
    <div class="title">Részletfizetés – Aktiválás</div>
    <div class="description">
      <strong>Aktiválja</strong> a részletfizetési tervét
      <strong>olyan részletekben, amit biztosan </strong>
      <strong>be tud vállalni.</strong> A részletek határideje előtt mindig
      értesítjük majd, de fontos, hogy
      <strong>mindig időben rendezze a részletet!</strong>
    </div>
    <app-custom-input-field appearance="outline">
      <button
        custom-input
        mat-button
        [matMenuTriggerFor]="paymentPlansMenu"
        class="pp-menu-button"
      >
        {{ selectedPayment }} részletben fizetem ki
      </button>
      <mat-menu #paymentPlansMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let count of caseData.payment_plan_options"
          (click)="selectPaymentPlan(count)"
        >
          {{ count }} részletben fizetem ki
        </button>
      </mat-menu>
      <span custom-label class="custom-label-top">Kommunikáció nyelve</span>
      <div custom-suffix style="pointer-events: none">
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    </app-custom-input-field>
    <div class="payment-plans-grid">
      <ng-container *ngFor="let pp of selectedPaymentPlans; let i = index">
        <div class="column-1">
          {{ i + 1 }}. részlet - Határidő: {{ pp.dueDate }}
        </div>
        <div class="column-2">
          {{ pp.paymentAmount }} {{ caseData.currency }}
        </div>
      </ng-container>
    </div>
    <form class="flex column checkbox-box">
      <app-custom-checkbox
        [checked]="pp.value"
        (onclick)="pp.setValue(!pp.value)"
      >
        <span class="checkbox-text">
          Elolvastam és elfogadom az
          <a href="https://www.payee.tech/legal/privacy-policy" target="_blank"
            ><strong>adatkezelési tájékoztatót.</strong></a
          >
        </span>
      </app-custom-checkbox>
      <app-custom-checkbox
        [checked]="aszf.value"
        (onclick)="aszf.setValue(!aszf.value)"
      >
        <span class="checkbox-text">
          Elolvastam és elfogadom az
          <a href="https://www.payee.tech/legal/terms-and-conditions" target="_blank"
            ><strong>ÁSZF</strong></a
          >-et.
        </span>
      </app-custom-checkbox>

      <div class="flex column buttons-box">
        <button
          mat-button
          class="accept-button"
          (click)="startPaymentPlan()"
          [disabled]="agreementForm.invalid || loading"
        >
          <span *ngIf="!loading">Aktiválom a részletfizetést</span>
          <div class="bw-theme flex hcenter" *ngIf="loading">
            <mat-progress-spinner
              color="accent"
              mode="indeterminate"
              diameter="30"
            >
            </mat-progress-spinner>
          </div>
        </button>
        <button mat-button class="cancel-button" mat-dialog-close>
          Mégsem
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="ppStarted">
    <!-- <div class="img-box">
      <lottie-player src="assets/5595-success-animation.json" background="transparent" speed="1" autoplay>
      </lottie-player>
    </div> -->
    <div class="title">Részletfizetés sikeresen aktiválva!</div>
    <div class="description">
      Küldtünk egy <strong>emailt,</strong> amiben
      <strong
        >részletesen leírjuk a legfontosabb<br />
        tudnivalókat</strong
      >
      a részletfizetési megállapodásról. Ezt kérjük,<br />
      <strong>mindenképpen olvassa el!</strong>
    </div>
    <div class="buttons-box">
      <button
        mat-button
        class="accept-button"
        mat-dialog-close
        style="margin-top: 17px"
      >
        Rendben
      </button>
    </div>
  </ng-container>
</div>
