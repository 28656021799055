<div class="wrapper">
  <div class="header">
    <span class="column-1">RÉSZLETEK</span>
    <span class="column-2">HATÁRIDŐ</span>
    <span class="column-3">ÖSSZEG</span>
  </div>
  <mat-divider></mat-divider>
  <div class="content">
    <ng-container *ngFor="let pp of caseData.payment_plans;let i=index">
      <div class="column-1 flex vcenter" [class.first]="i==firstPPIndex" *ngIf="pp.status!='paid'">
        <app-custom-checkbox *ngIf="i>=firstPPIndex" [checked]="selectedPayment>=i" (onclick)="selectPayment(i)">
          <span>{{i+1}}. részlet</span>
        </app-custom-checkbox>
      </div>
      <div class="column-1 flex vcenter paid" *ngIf="pp.status=='paid'">
        {{i+1}}. részlet
      </div>
      <span class="column-2" [class.first]="i==firstPPIndex" *ngIf="pp.status!='paid'">
        {{pp.dueDate  | date:'yyyy.MM.dd.'}}
      </span>
      <span class="column-2 paid flex vcenter" *ngIf="pp.status=='paid'">
        <img src="assets/arrow-down-circle.svg" width="20px" height="20px">&nbsp;
        <span>Fizetve</span>
      </span>
      <span class="column-3" [class.first]="i==firstPPIndex" *ngIf="pp.status!='paid'">
        {{pp.paymentAmountCurrent | number:'1.0':'hu'}} {{caseData.currency}}
      </span>
      <span class="column-3 paid" *ngIf="pp.status=='paid'">
        {{pp.paymentAmountOriginal | number:'1.0':'hu'}} {{caseData.currency}}
      </span>
    </ng-container>
  </div>
</div>