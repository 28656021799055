import { Component } from '@angular/core';

import * as smoothscroll from 'smoothscroll-polyfill';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor() {
    smoothscroll.polyfill();
  }

  scrollToDeposit(): void {
    const deposit = document.getElementById('deposit');
    deposit.scrollIntoView({
      behavior: 'smooth'
    });
  }
}
