import { MicrositeComponent } from './components/microsite/microsite.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { CasePaidDialogComponent } from './dialogs/case-paid-dialog/case-paid-dialog.component';
import { CaseFmhDialogComponent } from './dialogs/case-fmh-dialog/case-fmh-dialog.component';
import { CaseClosedDialogComponent } from './dialogs/case-closed-dialog/case-closed-dialog.component';
import { AfterPaymentRedirectionComponent } from './components/after-payment-redirection/after-payment-redirection.component';
import { PaymentRedirectStepComponent } from './components/payment-redirect-step/payment-redirect-step.component';


const routes: Routes = [
  { path: 'already-paid', component: CasePaidDialogComponent },
  { path: 'after-payment-redirection', component: AfterPaymentRedirectionComponent },
  { path: 'payment-success', component: PaymentSuccessComponent },
  { path: 'fmh', component: CaseFmhDialogComponent },
  { path: 'case-deleted', component: CaseClosedDialogComponent },
  { path: ':docID', pathMatch: 'full', component: MicrositeComponent },
  { path: 'hu/:docID', pathMatch: 'full', component: MicrositeComponent },
  { path: 'ro/:docID', pathMatch: 'full', component: MicrositeComponent },
  { path: 'case/:uuid/payment-redirect', component: PaymentRedirectStepComponent },
  { path: '**', component: MicrositeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
