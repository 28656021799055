import { Injectable } from '@angular/core';
import { CaseData } from 'src/app/models/case-data.model';
import { environment } from 'src/environments/environment';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  async getCaseDataByKey(docID: string): Promise<CaseData> {
    const url = environment.baseUrl + '/api/case/' + docID;
    const result = await axios.get<CaseData>(url);

    return result.data;
  }
}
