import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../../services/payment/payment.service';

@Component({
  selector: 'app-after-payment-redirection',
  templateUrl: './after-payment-redirection.component.html',
  styleUrls: ['./after-payment-redirection.component.scss']
})
export class AfterPaymentRedirectionComponent implements OnInit {
  paymentId: string;

  constructor(private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.route.queryParams
      .subscribe(params => {
        this.paymentId = params.paymentId;
      }
      );

    const barionLog = await this.paymentService.getBarionLog(this.paymentId);

    await this.navigateAccordingToPaymentStatus(barionLog);
  }

  async navigateAccordingToPaymentStatus(barionLog: any) {
    if (barionLog.status === 'Succeeded') {
      await this.router.navigate(['payment-success']);
    } else {
      const paymentSiteUrl = await this.paymentService.getPaymentSiteUrl(barionLog.clientId, barionLog.caseId);
      window.location.assign(paymentSiteUrl);
    }
  }
}

