import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-security-dialog',
  templateUrl: './security-dialog.component.html',
  styleUrls: ['./security-dialog.component.scss']
})
export class SecurityDialogComponent implements OnInit {

  securityForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.securityForm = this.fb.group({
      securityCode: ['', [Validators.required]]
    });
  }

  get securityCode(): AbstractControl {
    return this.securityForm.get('securityCode');
  }

  ngOnInit(): void {
  }

  checkAccess(s: any) {}

}
