import { CaseData } from '../../models/case-data.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CasesService } from 'src/app/services/cases/cases.service';

@Component({
  selector: 'app-microsite',
  templateUrl: './microsite.component.html',
  styleUrls: ['./microsite.component.scss']
})
export class MicrositeComponent implements OnInit {
  docID: string;
  loading = false;
  securityCode: string;
  caseData: CaseData;
  claimsListOpen = false;

  get windowWidth() {
    return window.innerWidth;
  }

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private casesService: CasesService,
  ) {
    this.titleService.setTitle('Payee - Payment');
    this.docID = this.route.snapshot.params.docID;
  }

  async ngOnInit() {
    if (!this.docID) {
      window.location.assign('https://payee.tech/');
      return;
    }

    const loadingTimer = setTimeout(() => this.loading = true, 250);
    try {
      this.caseData = await this.casesService.getCaseDataByKey(this.docID);
    } catch (error) {
      window.location.assign('https://payee.tech/');
    } finally {
      clearTimeout(loadingTimer);
      this.loading = false;
    }

    if (this.caseData.is_closed) {
      if (this.caseData.is_paid) {
        return await this.router.navigate(['already-paid']);
      } else {
        return await this.router.navigate(['case-deleted']);
      }
    }

    if (this.caseData.stage !== 'ONGOING' || this.caseData.product !== 'notice') {
      await this.router.navigate(['case-deleted']);
    }

    this.loading = false;
  }

  redirectToBarionGatewayUrl(gatewayUrl: string) {
    window.location.href = gatewayUrl;
  }

  async generateToken() {
    throw new Error('Unimplemented');
  }

  async getCaseDataByToken() {
    throw new Error('Unimplemented');
  }

  async getCaseDataByKey() {
    throw new Error('Unimplemented');
  }

  isCasePaused(caseData: CaseData): boolean {
    return false;
  }

  toggleClaimsList() {
    this.claimsListOpen = !this.claimsListOpen;
  }
}
