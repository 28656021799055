<div class="wrapper flex">
  <div class="bg-outter-box grow1">
    <div class="bg-inner-box">
      <img src="assets/payee-logo-white.svg" width="100px" />
    </div>
  </div>
  <div class="content grow1">
    <!-- <app-language-selector></app-language-selector> -->
    <div class="content-box flex column">
      <img class="check" src="assets/check.svg" />
      <span class="title">Sikeres befizetés!</span>
      <span class="description"
        >Sikeresen kifizetted tartozásodat. Köszönjük, hogy a Payee online és
        biztonságos rendszerét használtad tartozásod rendezéséhez.
      </span>
    </div>
  </div>
</div>
