import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { FooterComponent } from './components/footer/footer.component';
import { MicrositeComponent } from './components/microsite/microsite.component';
import { SecurityDialogComponent } from './dialogs/security-dialog/security-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/microsite/header/header.component';
import { DetailsComponent } from './components/microsite/details/details.component';

import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { CountdownComponent } from './components/microsite/countdown/countdown.component';
import { DepositComponent } from './components/microsite/deposit/deposit.component';
import { RedirectGuard } from './services/redirect/redirect.guard';
import { FullPaymentComponent } from './components/microsite/deposit/full-payment/full-payment.component';
import { PaymentPlanComponent } from './components/microsite/deposit/payment-plan/payment-plan.component';
import { CasePaidDialogComponent } from './dialogs/case-paid-dialog/case-paid-dialog.component';
import { CaseClosedDialogComponent } from './dialogs/case-closed-dialog/case-closed-dialog.component';
import { CaseFmhDialogComponent } from './dialogs/case-fmh-dialog/case-fmh-dialog.component';
import { ClaimsListComponent } from './components/microsite/claims-list/claims-list.component';
import { BankTransferComponent } from './components/microsite/bank-transfer/bank-transfer.component';
import { CustomInputFieldComponent } from './components/custom-input-field/custom-input-field.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { AfterPaymentRedirectionComponent } from './components/after-payment-redirection/after-payment-redirection.component';
import { PayeeIconModule } from './shared/payee-icon/payee-icon.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaymentPlanStartDialogComponent } from './dialogs/payment-plan-start-dialog/payment-plan-start-dialog.component';
import { PaymentRedirectStepComponent } from './components/payment-redirect-step/payment-redirect-step.component';
import { LoadingScreenModule } from './shared/loading-screen/loading-screen.module';
registerLocaleData(localeHu, 'hu');

@NgModule({
  declarations: [
    AppComponent,
    PaymentSuccessComponent,
    FooterComponent,
    MicrositeComponent,
    SecurityDialogComponent,
    HeaderComponent,
    DetailsComponent,
    CountdownComponent,
    DepositComponent,
    FooterComponent,
    FullPaymentComponent,
    PaymentPlanComponent,
    CasePaidDialogComponent,
    CaseClosedDialogComponent,
    CaseFmhDialogComponent,
    ClaimsListComponent,
    BankTransferComponent,
    CustomInputFieldComponent,
    LanguageSelectorComponent,
    CustomCheckboxComponent,
    AfterPaymentRedirectionComponent,
    PaymentPlanStartDialogComponent,
    PaymentRedirectStepComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MatTooltipModule,
    MatDividerModule,
    PayeeIconModule,
    MatFormFieldModule,
    LoadingScreenModule,
  ],
  providers: [RedirectGuard],
  bootstrap: [AppComponent]
})
export class AppModule {}
