<div class="wrapper flex vcenter">
  <mat-icon>language</mat-icon>
  <button mat-flat-button [matMenuTriggerFor]="menu">
    {{siteLanguage}}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a mat-menu-item *ngFor="let lang of languageList" (click)="changeLanguage(lang.code)">
      {{lang.label}}
    </a>
  </mat-menu>
</div>