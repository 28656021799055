import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor(
    private title: Title
  ) {
    this.title.setTitle('Sikeres fizetés! - Payee');
  }

  ngOnInit(): void {
  }

}
