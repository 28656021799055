import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-paid-dialog',
  templateUrl: './case-paid-dialog.component.html',
  styleUrls: ['./case-paid-dialog.component.scss']
})
export class CasePaidDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
