import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaseData } from 'src/app/models/case-data.model';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-full-payment',
  templateUrl: './full-payment.component.html',
  styleUrls: ['./full-payment.component.scss']
})
export class FullPaymentComponent implements OnInit {
  @Input() caseData: CaseData;
  paymentForm: FormGroup;
  keyId: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    ) {
    this.paymentForm = this.fb.group({
      aszf: [false, [Validators.requiredTrue]],
      pp: [false, [Validators.requiredTrue]]
    });
    this.keyId = this.route.snapshot.params.docID;
  }

  ngOnInit(): void {
  }

  async startPayment(): Promise<void> {
    throw new Error('Unimplemented');
  }
}
