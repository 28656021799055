<!-- <app-language-selector></app-language-selector> -->

<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img src="assets/doc.svg" height="71.43px" />
  </div>
  <div class="title flex vcenter">Ez az ügy jogi szakaszba lépett.</div>
  <div class="flex grow1 vcenter">
    <div class="description">
      Mivel nem került rendezésre a tartozás a határidőig, az ügy<br />
      <strong>automatikusan</strong> a jogi szakaszhoz érkezett.
    </div>
  </div>
  <a href="https://payee.tech">
    <button mat-button class="confirm-button">Bővebb információ</button>
  </a>
</div>
