<div class="wrapper">
  <span class="header left">KÖVETELÉS</span>
  <span class="header right">LEJÁRATI HATÁRIDŐ</span>
  <span class="header right">ÖSSZEG</span>
  <mat-divider></mat-divider>
  <ng-container *ngFor="let claim of caseData.claims; let i = index">
    <span class="data left">
      <span>{{ i + 1 }}. követelés</span>
      <a
        *ngIf="claim.file"
        [href]="claim.file.storage_url"
        target="_blank"
        style="margin-left: 15px;"
      >
        {{ claim.file.file_name }}
      </a>
    </span>
    <span class="data right">{{
      claim.expired_at | date : "yyyy.MM.dd."
    }}</span>
    <span class="data right"
      >{{ claim.remaining_amount | number : "1.0" : "hu" }}
      {{ caseData.currency }}</span
    >
  </ng-container>
</div>
