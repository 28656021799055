<div class="container flex">
  <div class="flex grow1 hcenter vcenter">
    <div class="flex column">
      <app-full-payment [caseData]="caseData"></app-full-payment>
      <a
        class="claims-button"
        (click)="
          claimsListOpen = !claimsListOpen;
          toggleClaimsList.emit(claimsListOpen)
        "
      >
        <ng-container *ngIf="!claimsListOpen">
          <img src="assets/expand_more_24px.svg" height="24px" />
          <span>MIBŐL ÁLL AZ ÖSSZEG?</span>
        </ng-container>
        <ng-container *ngIf="claimsListOpen">
          <img src="assets/expand_less_24px.svg" height="24px" />
          <span>RÉSZLETEK ELREJTÉSE</span>
        </ng-container>
      </a>
    </div>
  </div>

  <app-bank-transfer [caseData]="caseData"></app-bank-transfer>

  <div class="flex column payment-box" *ngIf="caseData.can_pay_with_barion">
    <form [formGroup]="paymentForm" class="flex column">
      <app-custom-checkbox
        [checked]="!!aszf.value"
        (onclick)="aszf.setValue(!aszf.value)"
      >
        <span class="checkbox">
          Elolvastam és elfogadom az
          <a href="https://www.payee.tech/legal/privacy-policy" target="_blank"
            ><strong>adatkezelési tájékoztatót.</strong></a
          >
        </span>
      </app-custom-checkbox>
      <app-custom-checkbox
        [checked]="!!pp.value"
        (onclick)="pp.setValue(!pp.value)"
      >
        <span class="checkbox">
          Elolvastam és elfogadom az
          <a href="https://www.payee.tech/legal/terms-and-conditions" target="_blank"
            ><strong>ÁSZF</strong></a
          >-et.
        </span>
      </app-custom-checkbox>
    </form>

    <button
      class="payment-button"
      mat-button
      [disabled]="paymentForm.invalid || paymentLoading"
      (click)="startPayment()"
    >
      <div class="bw-theme flex hcenter" *ngIf="paymentLoading">
        <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
        </mat-progress-spinner>
      </div>
      <ng-container *ngIf="!paymentLoading">
        <ng-container *ngIf="caseData.is_payment_plan">
          {{ paymentAmount | number : "1.0" : "hu" }}
          {{ caseData.currency }} kifizetése most
        </ng-container>
        <ng-container *ngIf="!caseData.is_payment_plan">
          Kifizetem most bankkártyával
        </ng-container>
      </ng-container>
    </button>
    <button
      class="payment-plan-start-button"
      mat-button
      [disabled]="paymentLoading"
      (click)="startPaymentPlan()"
      *ngIf="caseData.payment_plan_enabled"
    >
      <ng-container *ngIf="!paymentLoading">
        <ng-container> Részletfizetést kérek </ng-container>
      </ng-container>
    </button>
    <img
      class="barion-strip"
      src="assets/barion-card-strip-intl-updated.svg"
      width="286.5px"
    />
    <div class="flex vcenter secure-text">
      <img src="assets/lock.svg" />&nbsp;
      <span> <strong>100% biztonságos</strong> fizetés. </span>
    </div>
  </div>
</div>
