<div class="wrapper flex column">
    <div class="flex column vcenter grow1">
      <div class="title">Ellenőrző kód megadása</div>
      <div class="description">Ezt csak egyszer, most kell megadnod. Később a profilodban tudod módostani. Ide fogod kapni a befolyt összegeket.</div>
      <form [formGroup]="securityForm">
        <div class="label">ellenőrzőkódod</div>
        <div class="custom-input-field">
          <input class="custom-input" (input)="checkAccess(securityCode)" formControlName="securityCode">
          <mat-error *ngIf="securityCode.dirty && !securityCode.value">A mező kitöltése kötelező!</mat-error>
        </div>
      </form>
    </div>
    <div class="flex buttons">
      <button mat-button [mat-dialog-close]="false" cdkFocusInitial class="decline">Mégsem</button>
      <button mat-button [mat-dialog-close]="securityCode.value" [disabled]="securityCode.invalid" cdkFocusInitial class="accept">MENTÉS ÉS INDÍTÁS</button>
    </div>
  </div>
  